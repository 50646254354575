import * as Agent from './agent'
import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Urql from 'urql'

import PropTypes from 'prop-types'
import {devtoolsExchange} from '@urql/devtools'
import {suspenseExchange} from '@urql/exchange-suspense'

function applyWhen(cond, exchange) {
  if (cond()) {
    return exchange
  }

  return ({forward}) => forward
}

export function GraphqlProvider(props) {
  const clientType = ReactRedux.useSelector((state) =>
    Configuration.getClientType(state.configuration)
  )
  const url = ReactRedux.useSelector((state) =>
    Configuration.getGqlUrl(state.configuration)
  )

  const [token] = Cookies.useCookie(Constants.CookieKeys.TOKEN)

  const agent = React.useMemo(() => Agent.getAgentByUrl(url), [url])

  const client = React.useMemo(
    () =>
      url &&
      Urql.createClient({
        exchanges: [
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            devtoolsExchange
          ),
          Urql.dedupExchange,
          applyWhen(() => Boolean(process.browser), suspenseExchange),
          Urql.cacheExchange,
          props.ssrCache,
          Urql.fetchExchange,
        ],
        fetchOptions: {
          agent,
          headers: R.reject(R.isNil, {
            'Authorization': token,
            'Frontend-Client-Type': clientType,
            'Frontend-Country-Code': 'JP',
            'Frontend-Language': 'ja',
          }),
        },
        // We ran into issue with thrown promises not being caught by React
        // Reconciler, which caused app crash/
        // TODO: Enable client-side Suspense when possible
        suspense: !process.browser,
        url,
      }),
    [agent, clientType, props.ssrCache, token, url]
  )

  return <Urql.Provider value={client}>{props.children}</Urql.Provider>
}

GraphqlProvider.propTypes = {
  children: PropTypes.element,
  ssrCache: PropTypes.func,
  url: PropTypes.string,
}
