export const CookieKeys = {
  CLIENT_TYPE: 'client_type',
  COUNTRY_CODE: 'country_code',
  DEVICE_PIXEL_RATIO: 'device_pixel_ratio',
  LANGAUGE: 'language',
  RETURNING_PLAYER: 'returning_player',
  TOKEN: 'token',
}

export const ClientType = {
  BROWSER: 'browser',
  MOBILE_BROWSER: 'mobile-browser',
  UNKNOWN: 'unknown',
}

export const EmailPattern = '\\S+@\\S+\\.\\S+'
export const PasswordPattern =
  "^(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*?#\\-+\\/'^:,(){}[\\]~`_.])[A-Za-z\\d@$!%*?#\\-+\\/'^:,(){}[\\]~`_.]{8,}$"

export const GameTileHeight = {
  LARGE: 435,
  MEDIUM: 306,
  SMALL: 187,
}
export const GAME_TILE_MULTIPLIER = 0.55
export const GameTileProviderLogosWidthsDefault = ['48px', '64px']
export const GameTileWidth = {
  LARGE: 353,
  MEDIUM: 249,
  SMALL: 152,
}

export const BrandName = {
  casitabifree: 'Casitabi - Free',
}
