// import * as Configuration from './configuration'
import * as Cookies from './cookies-module'
import * as Herz from '@rushplay/herz'
import * as Pwa from './pwa'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {GraphqlProvider} from './graphql-provider'
import {HelmetProvider} from 'react-helmet-async'
import PropTypes from 'prop-types'
import {Router} from './router'
import {defaultTheme} from './theme'

/**
 * Extracted logic for data-providers to application
 * @component GlobalProvider
 * @param {Object} props Components props
 * @param {ReactNode} props.children Application consumer
 * @param {string} props.location Location for Router
 * @param {Object} props.store Redux store
 * @param {Object} props.translationLoader Loader for translations
 * @returns {ReactNode}
 */
export function GlobalProvider(props) {
  // const state = props.store.getState()

  // const languages = Configuration.getLanguageFallbackList(state.configuration)

  return (
    <Suspense.Provider>
      <HelmetProvider context={props.context}>
        <Herz.I18n.LoaderProvider loader={props.translationLoader}>
          <ReactRedux.Provider store={props.store}>
            <Cookies.Provider cookies={props.cookies}>
              <Pwa.Provider>
                <Router location={props.location}>
                  <GraphqlProvider ssrCache={props.ssrCache}>
                    <ThemeUi.ThemeProvider theme={defaultTheme}>
                      <Herz.I18n.TranslationProvider language={['ja', 'en']}>
                        {props.children}
                      </Herz.I18n.TranslationProvider>
                    </ThemeUi.ThemeProvider>
                  </GraphqlProvider>
                </Router>
              </Pwa.Provider>
            </Cookies.Provider>
          </ReactRedux.Provider>
        </Herz.I18n.LoaderProvider>
      </HelmetProvider>
    </Suspense.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.element,
  context: PropTypes.object,
  cookies: PropTypes.object,
  location: PropTypes.string,
  ssrCache: PropTypes.func,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  translationLoader: PropTypes.object.isRequired,
}
