import * as React from 'react'

import PropTypes from 'prop-types'

const PwaContext = React.createContext()

export function Provider(props) {
  const [deferredPrompt, setDeferredPrompt] = React.useState(null)
  const [showButton, setShowButton] = React.useState(false)

  React.useEffect(() => {
    function beforeInstallPrompt(e) {
      e.preventDefault()
      setShowButton(true)
      setDeferredPrompt(e)
    }
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt)
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt)
    }
  }, [])

  // uninstall the service worker (sw)
  React.useEffect(() => () => window['uninstall'](), [])

  const installPwa = React.useCallback(() => {
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        setShowButton(false)
      }
      setDeferredPrompt(null)
    })
  }, [deferredPrompt])

  const value = React.useMemo(
    () => ({
      visible: showButton,
      onInstall: installPwa,
    }),
    [showButton, installPwa]
  )

  return (
    <PwaContext.Provider value={value}>{props.children}</PwaContext.Provider>
  )
}

Provider.displayName = 'Pwa.Provider'

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useContext() {
  return React.useContext(PwaContext)
}
